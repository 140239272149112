import {GetOldValueOnInput} from "@/Helpers/GetValue";

export default {
    data() {
        return {
            loading: false,
            loadingView: false,
            TableLoading: false,
            modalConfirmClose: false,
            exportLoading: false,
            newCustomer: false,
            dialogChangePassword: false,
            updatePoint: false,
            edit_customer: {},
            updateMoney: false,
            showImage: false,
            addValue: {},
            new_password: '',
            closeId: "",
            remark: "",
            customerType: [],
            customerCode: '',
            selectedCustomerType: "",
            customerTypeId: "",
            customerID: "",
            selectedDistrict: "",
            selectedVillage: "",
            listDistricts: [],
            listVillages: [],
            selectedDistrictAdd: "",
            filterVillages: [],
            packages: [],
            package_Id: "",
            selectedPackage: "",
            server_errors: {
                email: "",
                customer_code: "",
                phone: "",
            },
            new_customers: [],
            countNew: 0,
            customer: {},
            customers: [],
            count: 0,
            customerDetail: [],
            rolesID: {},
            village: {},
            customer_edit: {},
            //Pagination
            offset: 15,
            pagination: {},
            per_page: 70,
            oldVal: "",
            search: '',
            //Location
            center: {
                lat: 18.1189434,
                lng: 102.290218,
            },
            markers: [],
            currentPlace: null,
            // package
            free_first_customer: 'no',
            isFirstDiscount: false,
            bottle_giveaway: 'no',
            isaccumulate_bottle: false,
            point_giveaway: 'no',
            isaccumulate_point: false,
            listItem: [
                {
                    id: 1,
                    name: "weeks"
                },
                {
                    id: 2,
                    name: "monthly"
                }
            ],
            selectedItem: '',

            discountType: [
                {
                    value: "percent",
                    name: "ເປີເຊັນ"
                },
                {
                    value: "cash",
                    name: "ຈຳນວນເງິນ"
                }
            ],
            selectedDiscountType: 'percent',
            discount_special: 0,

            markerOptions: {
                // eslint-disable-next-line global-require
                url: require('@coms/../../src/assets/vari_pin.png'),
                size: {
                    width: 35,
                    height: 55,
                    f: 'px',
                    b: 'px',
                },
                scaledSize: {
                    width: 35,
                    height: 55,
                    f: 'px',
                    b: 'px',
                },
            },
            timer: null,

            // Service-Zone
            checkedServiceZone: [],
            status: [
                {
                    id: 1,
                    name: "pending",
                },
                {
                    id: 2,
                    name: "approved",
                },
                {
                    id: 3,
                    name: "reject",
                },
            ],
            statusValue: "",
            sources: [
                {
                    id: 1,
                    value: "recommendation",
                    name: "ຄົນຮູ້ຈັກແນະນຳ",
                },
                {
                    id: 2,
                    value: "online",
                    name: "ທາງອອນໄລ",
                },
                {
                    id: 3,
                    value: "booth",
                    name: "ລົງບູດ",
                },
                {
                    id: 4,
                    value: "direct_to_customer",
                    name: "ລົງຫາລູກຄ້າ",
                },
                {
                    id: 5,
                    value: "instant_customer",
                    name: "ລູກຄ້າລະຫວ່າງທາງ",
                },
            ],
            sourceValue: "recommendation",
            fullImage: "",
            deliverable_time: [
                {
                    id: 1,
                    value: "9:00_to_16:00_narmal_time",
                    name: "9:00 ຫາ 16:00",
                },
                {
                    id: 2,
                    value: "18:00_to_20:00_special_time",
                    name: "18:00 ຫາ 20:00",
                },
                {
                    id: 3,
                    value: "weekly",
                    name: "ຈັດສົ່ງທຸກທິດ",
                },
                {
                    id: 4,
                    value: "2_per_weekly",
                    name: "ຈັດສົ່ງ 2 ທິດຄັ້ງ",
                },
                {
                    id: 5,
                    value: "monthly",
                    name: "ຈັກສົ່ງເດືອນລະຄັ້ງ",
                },
                {
                    id: 6,
                    value: "onCall",
                    name: "ໝົດເເລ້ວຈະໂທຫາ",
                },
            ],
            deliverable_time_value: "weekly",
            delivery_day: "",
            days: [],
            headers: [
                {text: "", value: "actions", sortable: false, width: "40px", align: "center"},
                {
                    text: "ລະຫັດລູກຄ້າ",
                    align: "start",
                    value: "customer_code",
                    sortable: false,
                    width: "150px"
                },
                {
                    text: "ວັນທີ", value: "createdDate", sortable: true,
                    width: "130px"
                },
                {
                    text: "ຊື່",
                    align: "start",
                    value: "name",
                    width: "150px"
                },
                {
                    text: "ປະເພດ",
                    align: "start",
                    value: "customer_type",
                    width: "150px"
                },

                {
                    text: "ຜູ້ຮັບຜິດຊອບ",
                    align: "start",
                    value: "organization_name", sortable: false,
                    width: "150px"
                },
                {text: "ເບີໂທ", value: "phone", sortable: false, width: "100px"},
                // { text: "Email", value: "email" },
                {text: "ທີ່ຢູ່", value: "village", width: "250px"},
                // { text: "ລາຍລະອຽດທີ່ຢູ່", value: "address" },
                {text: "ແພັກເກດ", value: "package.name", sortable: false, width: "150px"},
                // {text: "ລາຄາຕໍ່ຕຸກ", value: "package_detail", sortable: false, width: "150px"},
                // {text: "ທີ່ມາ", value: "source"},
                // { text: "Created By", value: "created_by" },
                // {text: "ປະເພດສ່ວນຫຼຸດ", align: "center", value: "package_detail.discount_special_type", width: "140px"},
                {text: "ສ່ວນຫຼຸດພິເສດ", align: "center", value: "package_detail.discount_special", width: "140px"},
                {text: "ຕຸກສະສົມ", align: "center", value: "total_bottle", width: "140px"},
                // {text: "ຄະແນນສະສົມ", align: "center", value: "point", width: "150px"},
                // {text: "ສ່ວນຫຼຸດສະສົມ", align: "center", value: "accumulated_purchase_number", width: "150px"},
                { text: "ເງິນມັດຈຳຍັງຄ້າງ", align: "center", value: "debtTotalBondingFee", width: "100px" },
                {text: "ມັດຈຳທັງໝົດ", align: "center", value: "totalBondingFee", width: "140px"},
                {text: "ສະຖານະ", value: "status", sortable: false, width: "100px"},
                {text: "ເວລາຈັດສົ່ງ", value: "deliverable_time", sortable: true, width: "120px"},
                {
                    text: "ວັນຈັດສົ່ງ",
                    align: "start",
                    value: "days",
                    sortable: false,
                    width: "100px"
                },
                {
                    text: "Profile",
                    align: "start",
                    value: "image",
                    sortable: false,
                    width: "50px"
                },
                {
                    text: "ຮູບເຮືອນ",
                    align: "start",
                    value: "home_image",
                    sortable: false,
                    width: "80px"
                },
                // { text: "Created By", value: "created_by" },
                // {text: "ຈັດການ", value: "actions", sortable: false, width: "150px"},
            ],
            ruleRole: [(v) => !!v || "User Role is required"],
            rulePackage: [(v) => !!v || "Package is required"],
            ruleDistrict: [(v) => !!v || "District is required"],
            ruleVillage: [(v) => !!v || "Village is required"],
            ruleSource: [(v) => !!v || "Source is required"],
            ruleTime: [(v) => !!v || "Delivery time is required"],
            ruleRemark: [(v) => !!v || "Remark is required"],
            emailRules: [
                (v) => !!v || "E-mail is required",
                (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
            ],
            nameRules: [
                (v) => !!v || "Name is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],
            organizeNameRules: [
                (v) => !!v || "Organization name is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],
            customerCodeRules: [
                (v) => !!v || "Customer code is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],
            phoneRules: [
                (v) => !!v || "Phone is required",
                (v) =>
                    (v && v.length >= 4 && v.length <= 11) ||
                    "Phone number must be  4 - 11 numbers",
            ],
            addressRules: [
                (v) => !!v || "Name is required",
                (v) => (v && v.length >= 2) || "Must be less than 2 characters",
            ],
            ///validate package
            rulePer: [(v) => !!v || 'ກາລຸນາເລືອກໄລຍະເວລາ',],
            bondingRules: [
                (v) => !!v || "ກາລຸນາປ້ອນຄ່າມັນຈຳ",
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be  1 - 6 numbers',
            ],
            priceRules: [
                v => !!v || 'ກາລຸນາປ້ອນລາຄາຕໍ່ຕຸກ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],
            bottleRules: [
                v => !!v || 'ກາລຸນາໃສ່ຈຳນວນຕຸກນ້ຳຂັ້ນຕ່ຳ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],
            pointRules: [
                v => !!v || 'ກາລຸນາໃສ່ຈຳນວນຄະແນນ',
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],
            passwordRules: [
                (v) => !!v || "Password is required",
                (v) =>
                    (v && v.length >= 6) || "Password must be more than 6 characters",
            ],
            totalRules: [
                (v) => !!v || "ກາລຸນາໃສ່ຈຳນວນເງິນ",
                // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
            ],

            toast: {
                value: true,
                color: "success",
                msg: "Success",
            },
            toast_error: {
                value: true,
                color: "error",
                msg: "Something when wrong!",
            },
        };
    },
    methods: {
        OpenModalAdd() {
            this.$store.commit("modalAdd_State", true);
        },
        routeCreate() {
            this.$router.push({name: 'CustomerCreate'});
        },
        AddItem() {
            if (this.$refs.form.validate() == true) {
                this.SubmitItem();
            }
        },
        SubmitItem() {

            this.customer.deliverable_time = this.deliverable_time_value;
            this.customer.source = this.sourceValue;
            this.customer.customer_type_id = this.selectedCustomerType;
            this.customer.village_id = this.selectedVillage.id;
            this.customer.packageId = this.selectedPackage;
            this.customer.latitude = this.center.lat;
            this.customer.longitude = this.center.lng;
            this.customer.free_first_customer = this.free_first_customer;
            this.customer.bottle_giveaway = this.bottle_giveaway;
            this.customer.point_giveaway = this.point_giveaway;
            this.customer.delivery_day = this.delivery_day;
            this.customer.discount_special_type = this.selectedDiscountType;
            this.customer.discount_special = this.discount_special;
            this.customer.price = 0;
            // this.customer.roleId = 6;
            this.loading = true;
            this.$admin
                .post("add-customer", this.customer)
                .then((res) => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.loading = false;
                            this.customer = {};
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                            this.$router
                                .push({ name: "Customer", query: { tab: "customer" } })
                                .catch(() => {});
                        }, 300);
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                    this.fetchData();
                    if (error.response.status == 422) {
                        let obj = error.response.data.errors;
                        for (let [key, customer] of Object.entries(obj)) {
                            this.server_errors[key] = customer[0];
                        }
                    }
                });
        },

        accumulateBottle(value) {
            if (value == "yes") {
                return this.isaccumulate_bottle = true;
            } else if (value == "no") {
                this.customer.accumulated_purchase_number = 0;
                this.customer.accumulated_purchase_number = 0;
                return this.isaccumulate_bottle = false;

            }
        },
        accumulatePoint(value) {
            if (value == "yes") {
                return this.isaccumulate_point = true;
            } else if (value == "no") {
                this.customer.accumulated_purchase_point = 0;
                this.customer.accumulated_purchase_point = 0;
                return this.isaccumulate_point = false;

            }
        },
        //First Sale Discount
        firstSaleDiscount(value) {
            if (value == "yes") {
                return this.isFirstDiscount = true;
            } else if (value == "no") {
                this.customer.discount_first_customer = 0;
                return this.isFirstDiscount = false;

            }
        },

        CloseModalAdd() {
            this.customer = {};
            this.reset();
            this.fetchData();
            this.$store.commit("modalAdd_State", false);
        },
        routeBack() {
            this.$router.push({name: 'Customer'});
        },

        updateCustomer(id) {
            this.$router.push({name: 'CustomerUpdate', params: {id: id}});
        },
        OpenModalEdit(item) {
            this.customer_edit = item;
            this.$store.commit("modalEdit_State", true);
        },

        fetchData() {
            this.TableLoading = true;
            this.$admin.get('customer', {
                params: {
                    page: this.pagination.current_page,
                    per_page: this.per_page,
                    search: this.search
                }
            }).then(res => {
                setTimeout(() => {
                    this.customers = res.data.customers.data;
                    this.count = res.data.countCustomer;
                    this.pagination = res.data.customers;
                    this.TableLoading = false;
                }, 500);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });

        },

        fetchNewCustomer() {
            this.TableLoading = true;
            this.$admin.get('new-customer', {
                params: {
                    page: this.pagination.current_page,
                    per_page: this.per_page,
                }
            }).then(res => {
                setTimeout(() => {
                    this.new_customers = res.data.customers.data;
                    this.countNew = res.data.count;
                    this.pagination = res.data.customers;
                    this.TableLoading = false;
                }, 500);
            }).catch(() => {
                this.TableLoading = false;
                this.$store.commit("Toast_State", this.toast_error);
            });

        },

        FilterVillages(districtId) {
            const result_checking = this.filterVillages.filter((item) => {
                return item.district_id == districtId;
            });
            this.listVillages = result_checking;
            this.selectedVillage = {...this.listVillages[0]};
        },

        closeCustomer(id) {
            this.closeId = id;
            this.modalConfirmClose = true;
        },
        closeCustomerConfirm() {
            this.loading = true;
            this.$admin
                .post("close-customer-service/" + this.closeId, {remark: this.remark})
                .then((res) => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.fetchData();
                            this.modalConfirmClose = false;
                            this.loading = false;
                            this.remark = "";
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                })
                .catch(() => {
                    this.fetchData();
                    this.$store.commit("Toast_State", this.toast_error);
                    this.modalConfirmClose = false;
                    this.loading = false;
                });

        },

        closeDelete() {
            this.$store.commit("modalDelete_State", false);
        },

        deleteItem(id) {
            this.customerID = id;
            this.$store.commit("modalDelete_State", true);
        },

        deleteItemConfirm() {
            this.loading = true;
            this.$admin
                .delete("delete-customer/" + this.customerID)
                .then((res) => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.fetchData();
                            this.$store.commit("modalDelete_State", false);
                            this.loading = false;
                            this.$store.commit("Toast_State", this.toast);
                        }, 300);
                    }
                })
                .catch(() => {
                    this.fetchData();
                    this.$store.commit("Toast_State", this.toast_error);
                    this.$store.commit("modalDelete_State", false);
                    this.loading = false;
                });
        },
        reset() {
            this.$refs.form.reset();
        },

        RefreshVillage() {
            this.$admin
                .get("list-address")
                .then((res) => {
                    this.filterVillages = res.data.listVillages;
                    this.TableLoading = false;
                    this.FilterVillages(this.selectedDistrict);
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        OpenAddVillage() {
            this.$store.commit("modalAdd2_State", true);
        },

        CloseAddVillage() {
            this.$store.commit("modalAdd2_State", false);
        },
        getstatusColor(status) {
            if (status == "pending") return "primary--text";
            else if (status == "approved") return "success--text";
            else if (status == "reject") return "error--text";
            else return;
        },
        getStatusType(time) {
            if (time == '9:00_to_16:00_narmal_time') return '9:00 ຫາ 16:00 ເວລາປົກກະຕິ';
            else if (time == '18:00_to_20:00_special_time') return '18:00 ຫາ 20:00 ເວລາພິເສດ';
            else return;
        },
        source(s) {
            if (s == 'online') return 'ທາງອອນໄລ';
            else if (s == 'booth') return 'ລົງບູດ';
            else if (s == 'recommendation') return 'ຄົນຮູ້ຈັກແນະນຳ';
            else if (s == 'direct_to_customer') return 'ລົງຫາລູກຄ້າ';
            else if (s == 'instant_customer') return 'ລູກຄ້າລະຫວ່າງທາງ';
            else return;
        },

        getDateCreated(date) {
            let date_now = new Date(Date.now());
            date_now = this.moment(date_now).format("YYYY-MM-DD");
            if (this.moment(date).format("YYYY-MM-DD") == date_now) {
                return "error--text";
            } else {
                return "";
            }
        },

        viewInvoice(value) {
            this.loadingView = true;
            this.customerDetail = value;
            this.$store.commit("modalView_State", true);
        },
        closeModalView() {
            this.loadingView = false;
            this.$store.commit("modalView_State", false);
        },

        //Set Googlemap Api
        createNewAddressName() {
            const CUSTOMIZE = '#CUSTOM ADDRESS:';
            return this.isCreate ? this.currentAddress : `${CUSTOMIZE} ${this.center.lat}, ${this.center.lng}`;
        },
        onLocation(evt) {
            this.center.lat = evt.latLng.lat();
            this.center.lng = evt.latLng.lng();
            this.address = this.createNewAddressName();
            this.customer_edit.latitude = this.center.lat;
            this.customer_edit.longitude = this.center.lng;
        },
        setPlace(place) {
            this.currentPlace = place;
            this.placeMarker();
        },
        placeMarker() {
            this.markers = [];
            this.places = [];
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({position: marker});
                this.center = marker;
                this.animateMarker();
            } else {
                const marker = {
                    lat: this.center.lat,
                    lng: this.center.lng,
                };
                this.markers.push({position: marker});
                this.animateMarker();
            }
            // set address
            if (this.$refs.searchInput) {
                this.address = this.$refs.searchInput.$el.value;
            } else {
                // this.address = this.currentPlace.formatted_address;
            }
            this.onDataChange();
        },
        animateMarker() {
            this.$nextTick(() => {
                // const obj = this.$refs.markers[0].$markerObject;
                // if (obj) {
                //     obj.setAnimation(1);
                //     clearTimeout(this.timer);
                //     this.timer = setTimeout(() => {
                //         obj.setAnimation(null);
                //     }, 800);
                // }
            });
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                this.placeMarker();
            });

        },
        onDataChange() {
            this.$emit('onDataChange', {
                address: this.address,
                position: this.center,
            });
            // (this.center);
        },
        onSave() {
            this.$emit('onSave', {
                address: (this.address || this.currentAddress || 'Unnamed Location'),
                position: this.center,
                isCreate: this.isCreate,
            });
        },

        //Export excel
        exportData() {
            this.exportLoading = true;
            this.$axios
                .get(
                    "export/customers", {responseType: "blob"}
                )
                .then((res) => {
                    setTimeout(() => {
                        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                        const fileLink = document.createElement("a");
                        fileLink.href = fileUrl;
                        fileLink.setAttribute(
                            "download",
                            "customers" +
                            ".xlsx"
                        );
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        document.body.removeChild(fileLink);
                        this.exportLoading = false;
                    }, 100);
                })
                .catch(() => {
                    this.exportLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        //Change password
        OpenchangePassword(userId) {
            this.new_password = '',
                this.customerID = userId;
            this.dialogChangePassword = true;
        },
        ChangePassword() {
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$admin.post('change-password', {
                    password: this.new_password,
                    userId: this.customerID,
                }).then(res => {
                    if (res.data.success == true) {
                        setTimeout(() => {
                            this.dialogChangePassword = false;
                            this.new_password = '',
                                this.loading = false;
                            this.fetchData();
                            this.reset();
                            this.$store.commit("Toast_State", this.toast);
                        }, 300)
                    }
                }).catch(() => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                })
            }
        },

        openPoint(value) {
            this.updatePoint = true;
            this.edit_customer = value;
        },
        updateCusPoint() {
            if (this.$refs.form.validate() == true) {
                this.loading = true;
                this.$admin.post('update-point-customer/' + this.edit_customer.id,
                    {point: this.edit_customer.point})
                    .then(res => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.updatePoint = false;
                                this.edit_customer = {};
                                this.reset();
                                this.fetchData();
                                this.$store.commit("Toast_State", this.toast);
                            }, 300);
                        }
                    }).catch(() => {
                    this.loading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
            }
        },

        //Update Bonding Fees
        openModalUpdateBondingFees(id) {
            this.customerId = id;
            this.updateMoney = true;
        },
        closeUpdateBondingFees() {
            this.updateMoney = false;
        },
        updateBondingFees() {
            if (this.$refs.form.validate() == true) {
                this.addValue.customer_id = this.customerId;
                this.$admin
                    .post("add/bonding/fees", this.addValue)
                    .then((res) => {
                        if (res.data.success == true) {
                            setTimeout(() => {
                                this.loading = false;
                                this.fetchData();
                                this.closeUpdateBondingFees();
                                this.addValue = {};
                                this.closeInvoice();
                                this.reset();
                                this.$store.commit("Toast_State", this.toast);
                            }, 1000);
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                        this.$store.commit("Toast_State", this.toast_error);
                    });
            }
        },

        ///Search
        Search() {
            GetOldValueOnInput(this);
        },
        showFullIamage(value, type) {
            if (type == 'profile') {
                if (value.profile) {
                    this.showImage = true;
                }
                this.fullImage = value.profile;
            } else if (type == 'home') {
                if (value.home_image) {
                    this.showImage = true;
                }
                this.fullImage = value.home_image;
            }
        },

        async fetchCustomerType() {
            await this.$axios
                .get("list-customer-types")
                .then((res) => {
                    this.customerType = res.data.customerTypes;
                    this.TableLoading = false;
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        async fetchAddress() {
            await this.$admin
                .get("list-address")
                .then((res) => {
                    //  this.listVillages = res.data.listVillages;
                    this.listDistricts = res.data.districts;
                    this.filterVillages = res.data.listVillages;
                    this.TableLoading = false;
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        async fetchPackage() {
            await this.$admin
                .get("list-packages")
                .then((res) => {
                    this.packages = res.data.listPackages;
                    this.TableLoading = false;
                })
                .catch(() => {
                    this.TableLoading = false;
                    this.$store.commit("Toast_State", this.toast_error);
                });
        },

        async fetchDay() {
            await this.$axios
                .get("day")
                .then((res) => {
                    this.days = res.data.data;
                })
                .catch(() => {
                });
        },
        getDelivery(value) {
            if (value == '9:00_to_16:00_narmal_time') {
                return '9:00 ຫາ 16:00';
            } else if (value == '18:00_to_20:00_special_time') {
                return '18:00 ຫາ 20:00';
            } else if (value == 'weekly') {
                return 'ຈັດສົ່ງທຸກທິດ';
            } else if (value == '2_per_weekly') {
                return 'ຈັດສົ່ງ 2 ທິດຄັ້ງ';
            } else if (value == 'monthly') {
                return 'ຈັກສົ່ງເດືອນລະຄັ້ງ';
            } else if (value == 'onCall') {
                return 'ໝົດເເລ້ວຈະໂທຫາ';
            } else {
                return '---';
            }
        }
    },
    computed: {},
    watch: {
        selectedDistrict: function (dist_id) {
            this.FilterVillages(dist_id);
        },
        "customer_edit.district_id": function (value) {
            this.FilterVillages(value);
        },

        "customer.email": function () {
            this.server_errors.email = "";
        },
        "customer.phone": function () {
            this.server_errors.phone = "";
        },
        "customer.customer_code": function () {
            this.server_errors.customer_code = "";
        },

        'customer.price': function () {
            this.server_errors.price = '';
        },
        'customer.bottle_giveaway': function (value) {
            this.accumulateBottle(value);
        },
        'customer.point_giveaway': function (value) {
            this.accumulatePoint(value);
        },

        "customer.free_first_customer": function (value) {
            this.firstSaleDiscount(value);
        },
        'search': function (value) {
            if (value == '') {
                this.fetchData();
            }
        },
    },

    mounted() {
        this.geolocate();
    },
};
